<template>
	<div id="sales_dep">
		<!-- 搜索栏 -->
		<div class="main-body-filter">
			<div class="main-body-filter-left">
				<el-select v-model="filter.bigarea.value" :loading="filter.bigarea.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部区域" @change="selectFilter('area')">
					<el-option v-for="item in filter.bigarea.options" :key="item.bg_id" :label="item.bg_areaname"
						:value="item.bg_id">
					</el-option>
				</el-select>
				<el-select v-model="filter.group.value" :loading="filter.group.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部分组" @change="selectFilter">
					<el-option v-for="item in filter.group.options" :key="item.param_id" :label="item.title"
						:value="item.param_id">
					</el-option>
				</el-select>
				<el-select v-model="filter.status_id.value" :loading="filter.status_id.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部巡检情况" @change="selectFilter">
					<el-option v-for="item in filter.status_id.options" :key="item.id" :label="item.text"
						:value="item.id">
					</el-option>
				</el-select>
				<el-select v-model="filter.abnormal_id.value" :loading="filter.abnormal_id.is_load"
					class="filter-select" size="large" clearable filterable placeholder="全部异常状况" @change="selectFilter">
					<el-option v-for="item in filter.abnormal_id.options" :key="item.id" :label="item.text"
						:value="item.id">
					</el-option>
				</el-select>
				<el-select v-model="filter.handle_status.value" :loading="filter.handle_status.is_load"
					class="filter-select" size="large" clearable filterable placeholder="全部处理状态" @change="selectFilter">
					<el-option v-for="item in filter.handle_status.options" :key="item.id" :label="item.text"
						:value="item.id">
					</el-option>
				</el-select>
				<el-date-picker v-model="filter.date_time" type="date"  @change="selectFilter" placeholder="选择时间" size="large" />
			</div>
		</div>
		<div class="main-body-table">
			<!-- 处理栏 -->
			<div class="info-div">
				<el-button type="primary" @click="handleBtn" style="margin-right: 20px;">{{table.multipleSelection.length ? '标记已选'+table.multipleSelection.length+'条' : '批量处理'}}</el-button>
				<span>当前巡检总数：{{table.info.all_num}}，待巡检：{{table.info.wait_inspection_num}}，已巡检：{{table.info.has_inspection_num}}，（其中正常：{{table.info.normal_num}}，异常：{{table.info.abnormal_num}}【待处理：{{table.info.wait_handle_num}}，已处理：{{table.info.has_handle_num}}】）</span>
				<div class="table-head-icon" style="float: right;">
					<SelectFilePrint :datas="table.tableFilterList"  @selectClick="selectClick"></SelectFilePrint>
				</div>
			</div>
			<SelectTable id="printBill" :tables="table.list" :confs="table.conf" :filters="table.tableFilterList" :icons="['HelpFilled', 'VideoCameraFilled']" :operate="true" @sizeChange="sizeChange" @currentChange="currentChange" @iconClick="iconClick" @handleSelectionChange="handleSelectionChange"></SelectTable>
		</div>
		<!-- 视频预览 -->
		<div class="dialog-page">
			<PreviewVideo ref="preview_video"></PreviewVideo>
		</div>
	</div>
</template>

<script setup>
	import tableColumns from '@/assets/json/inspection/table/inspection-mgr.json'
	import SelectFilePrint from '@/components/common/SelectFilePrint.vue'
	import SelectTable from '@/components/common/SelectTable.vue'
	import CusTable from '@/components/common/CusTable.vue'
	import PreviewVideo from '@/components/inspection/previewVideo.vue'
	import {
		getCurrentInstance,
		ref,
		onMounted,
		reactive
	} from 'vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import { timeToDate } from '@/utils/tools.js'
	import { exportToExcel } from "@/utils/out_excel.js"; // 导出表格excel
	import { exportToCsv } from "@/utils/out_csv.js"; // 导出表格csv
	import { exportToPrint } from "@/utils/out_print.js"; // 打印

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 顶部筛选
	const filter = reactive({
		keywords: '',
		bigarea: {
			options: [],
			value: '',
			is_load: false
		},
		group: {
			options: [],
			value: '',
			is_load: false
		},
		date_time: new Date(),
		handle_status: {
			value: '',
			is_load: false,
			options: [{ id: 0, text: '全部处理状态' },
				{ id: 1, text: '无需处理' },
				{ id: 2, text: '待处理' },
				// { id: 2, text: '处理中' },
				{ id: 3, text: '已处理' }
			]
		},
		status_id: {
			value: '',
			is_load: false,
			options: [{ id: 0, text: '全部巡检情况' },
				{ id: 1, text: '正常状况' },
				{ id: 2, text: '异常状况' },
			]
		},
		abnormal_id: {
			value: '',
			is_load: false,
			options: [{ id: 0, text: '全部异常状况' },
				{ id: 1, text: '正常' },
				{ id: 2, text: '离线' },
				{ id: 3, text: '摄像头方向' },
				{ id: 4, text: '摄像头遮挡' },
				{ id: 5, text: '摄像时间' },
				{ id: 6, text: '抓拍时间' },
				{ id: 7, text: '摄像头模糊' }
			]
		}
	});

	// 列表信息
	const table = reactive({
		list: [],
		ezviz_account: {},
		columns: tableColumns.list,
		conf: {
			loadStatus: false,
			curPage: 1,
			pageLimit: 15,
			dataTotal: 0,
			sizes: [15, 50, 500, 3000],
			emptyText: ''
		},
		tableFilterList: tableColumns.list,
		info: {},
		multipleSelection: [] // 被多选的表格数据
	})
	const tableFilterClick = ref()

	// 选项筛选打开
	// const showClick = () => {
	// 	if (!tableFilterClick.value) return
	// 	tableFilterClick.value.handleOpen()
	// }

	// 获取统计数据
	const loadCount = () => {
		table.info = [];
		ctx.$request_nl_.post(ctx.$api_.state.Inspection.inspection_mgr.count.url, {
			area_id: filter.bigarea.value,
			group_id: filter.group.value,
			date_time: timeToDate(filter.date_time)
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				table.info = respon.Data
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}
		}).catch(error => {
			ctx.$message.error({
				message: error
			});
		})
	}

	// 获取table列表
	const loadList = () => {
		// 清除现有table数据
		table.list = []
		table.conf.loadStatus = true
		table.conf.emptyText = '数据查询中...'
		ctx.$request_nl_.post(ctx.$api_.state.Inspection.inspection_mgr.list.url, {
			area_id: filter.bigarea.value,
			group_id: filter.group.value,
			status_id: filter.status_id.value,
			abnormal_id: filter.abnormal_id.value,
			handle_status: filter.handle_status.value,
			date_time: timeToDate(filter.date_time),
			page: table.conf.curPage,
			limit: table.conf.pageLimit
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				table.conf.dataTotal = respon.Data.total
				table.list = respon.Data.data
				table.ezviz_account = respon.Data.ezviz_account
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}
			table.conf.loadStatus = false
			table.conf.emptyText = respon.Data.data.length > 0 ? respon.Message : "暂无更多数据"
		}).catch(error => {
			table.conf.loadStatus = false
			table.conf.emptyText = "服务器连接失败，请稍后重试"
		})
	}

	// 筛选框调用事件
	const selectFilter = async(t) => {
		if(t == 'area'){
			await getGroup()
		}
		table.conf.curPage = 1
		// 重新加载统计数据
		await loadCount()
		// 重新加载表格数据
		await loadList()
	}

	// 表格多选
	const handleSelectionChange = (val) => {
		table.multipleSelection = val
		for (let index = 0; index < table.tableFilterList.length; index++) {
			const element = table.tableFilterList[index];
			if (!element.show) {
				for (let i = 0; i < table.multipleSelection.length; i++) {
					const e = table.multipleSelection[i];
					delete e[element.prop].value
				}
			}
		}
	}
	
	// 输入框调用事件
	const inputFilter = () => {
		// 清空select选项框
		filter.bigarea.value = ''
		table.conf.curPage = 1
		// 重新加载table数据
		loadList()
	}

	// 设置table当前页码变化
	const currentChange = (curPage) => {
		// 存放当前页面
		table.conf.curPage = curPage
		// 重新加载table数据
		loadList()
	}

	// 设置table每页页码数
	const sizeChange = (limit, page) => {
		// 存放页码数
		table.conf.pageLimit = limit
		// 判断查询当前页  *  条数 > 总条数不进行查询
		if (table.conf.curPage === 1 || limit * table.conf.curPage <= table.conf.dataTotal) {
			// 重新加载table数据
			loadList()
		}
	}

	// 挂载
	const table_ref = ref(null)
	const add_sales_dep_ref = ref(null)
	const video_perview_ref = ref(null);
	const preview_video = ref(null)

	// 获取分组
	const getGroup = () => {
		ctx.$request_nl_.post(ctx.$api_.state.Carema.group.list.url, {
			bg_id: filter.bigarea.value,
			page: 1,
			limit: 1000
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				filter.group.options = respon.Data.list
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}
		})
	}

	// 标记已选
	const handleBtn = () => {
		if(table.multipleSelection.length) {
			let idArr = []
			table.multipleSelection.map((e) => {
				idArr.push(e.inspection_record_id)
			})
			ctx.$request_nl_.post(ctx.$api_.state.Inspection.inspection_mgr.record.url, {
				record_ids: idArr,
				handle_status: 2
			})
			.then((respon) => {
				if (respon.Code === ctx.$code_.state.success) {
					ctx.$message.success({
						message: '修改处理状态成功'
					});
					loadList();
				} else {
					ctx.$message.error({
						message: respon.Message
					});
				}
			})
		} else {
			ctx.$message.warning({
				message: '未选择要处理的数据'
			});
		}
	}
	
	// 导出和打印
	const selectClick = (val) => {
		if(val === 'excel') {
			let headArr = []
			for (let index = 0; index < table.tableFilterList.length; index++) {
				let element = table.tableFilterList[index];
				if(element.show) {
					headArr.push(element.label)
				}
			}
			let smallArr = []
			let tableArr = []
			for (let index = 0; index < table.list.length; index++) {
				smallArr = []
				let ele = table.list[index]
				for (let i = 0; i < table.tableFilterList.length; i++) {
					if(table.tableFilterList[i].show) {
						let e = table.tableFilterList[i].prop
						smallArr.push(ele[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToExcel(tableArr, headArr, "sheet1", "巡检管理");
		} else if(val === 'csv') {
			let headObj = {}
			for (let index = 0; index < table.tableFilterList.length; index++) {
				let element = table.tableFilterList[index];
				if(element.show) {
					headObj[element.label] = element.label
				}
			}
			let smallArr = {}
			let tableArr = []
			for (let index = 0; index < table.list.length; index++) {
				smallArr = {}
				let ele = table.list[index]
				for (let i = 0; i < table.tableFilterList.length; i++) {
					if(table.tableFilterList[i].show) {
						let e = table.tableFilterList[i].prop
						smallArr[e] = (ele[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToCsv(tableArr, headObj, '巡检管理')
		} else if(val === 'print') {
			exportToPrint('printBill')
		}
	}

	// 操作点击
	const iconClick = (val, item) => {
		switch(val){
			case 'HelpFilled':
				// 同步设备信息通道
				ElMessageBox.confirm(
					'您确认要同步该设备信息通道吗?',
					'温馨提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning',
				}).then(() => {
					getVideoAddress(item)
				})
				break;
			case 'VideoCameraFilled':
				// 预览
				toPreviewVideo(item)
				break;
			default:
				break;
		}
	}

	// 操作获取视频地址
	const getVideoAddress = (item) => {
		ctx.$request_nl_.post(ctx.$api_.state.Carema.channels.synch.url, {
			e_id: item.equipment_id
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				ctx.$message.success({
					message: '获取视频地址成功'
				});
				loadList();
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}
		})
	}

	// 预览视频
	const toPreviewVideo = (item) => {
		let data = {
			group_name: item.group_name,
			device_name: item.equipment_name,
			device_sn: item.hik_no,
			channel_no: item.channel_no,
			ezviz_account: table.ezviz_account,
		}
		preview_video.value.openPage(data)
	}

	onMounted(() => {
		// 初始化加载区域列表
		ctx.$pubFun_.getSelectCommonBigarea(filter.bigarea)
		// 初始化条件筛选分组
		selectFilter('area')
		// 初始化统计数据
		loadCount()
		// 初始化加载表格列表
		loadList()
	})
</script>